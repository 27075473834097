.pk-loader {
  fill: none;
  stroke-width: 4px;
  //stroke: white;
  stroke-dasharray: 40;
  stroke-dashoffset: 50%;
  animation-name: pkMove;
  animation-duration: 0.7s;
  animation-fill-mode: forwards;
  animation-delay: 0s;
  animation-iteration-count: infinite;
}

@keyframes pkMove {
  to {
    stroke-dashoffset: 250%
  }
}

.pkLoaderContent {
  position: absolute;
  top: calc(50% - 17px);
  left: calc(50% - 17px);
}
