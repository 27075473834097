@import "variables";
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  display: flex;

  &__shadow {
    width: calc(100% + 15px);
    height: 100%;
    background-color: rgba(0, 0, 0, .65);
    z-index: 1;
    position: fixed;
    top: 0;
    left: -15px;
  }

  &__wrapper {
    width: 550px;
    height: auto;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 61px;
    left: calc(50% - 375px);
    z-index: 2;
    padding: 38px 100px;
    font-family:$main-font-family;
    text-align: left;
    margin-bottom: 61px;
  }

  &__input {
    width: 160px;
    height: 18px;
    padding: 9px 29px 9px 9px;
    font-size: 14px;
    border: 1px solid #147dfb;
    border-radius: 3px;
    outline: 0;

    &.error {
      border: 2px solid #ff9c00!important;
    }
  }

  &__title {
    font-size: 25px;
    margin: 0;
    text-align: center;
    font-weight: 700;

    &--uppercase {
      text-transform: uppercase;
    }
  }

  &__description {
    margin: 0;
    margin-top: 38px;
    font-weight: 300;

    b {
      font-weight: bold;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;

    button:first-child {
      margin-right: 15px;
    }
  }

  &__button {
    width: 200px;
    line-height: 40px;
    text-align: center;
    background-color: #147dfb;
    border-radius: 3px;
    border: 0;
    padding: 0 10px;
    font-size: 17px;
    font-weight: 700;
    transition: .3s;
    color: #fff;
    margin-top: 38px;
    position: relative;
    cursor: pointer;
    display: inline-block;
    text-decoration: none;

    &:hover {
      background-color: #4397fc;
    }

    &--close {
      background-color: #fff;
      border: 1px solid #147dfb;
      line-height: 38px;
      padding: 0;
      color: #147dfb;

      &:hover {
        color: #fff;
        background-color: #147dfb;
      }
    }

    &--disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
