@import "includes";

@keyframes shake {
	10%, 90% {
		transform: translate3d(-1px, 0, 0);
	}

	20%, 80% {
		transform: translate3d(2px, 0, 0);
	}

	30%, 50%, 70% {
		transform: translate3d(-4px, 0, 0);
	}

	40%, 60% {
		transform: translate3d(4px, 0, 0);
	}
}

/*
 *  Owl Carousel - Animate Plugin
 */

.owl-carousel {
	.animated {
		-webkit-animation-duration: 1000ms;
		animation-duration: 1000ms;
		-webkit-animation-fill-mode: both;
		animation-fill-mode: both;
	}

	.owl-animated-in {
		z-index: 0;
	}

	.owl-animated-out {
		z-index: 1;
	}

	.fadeOut {
		-webkit-animation-name: fadeOut;
		animation-name: fadeOut;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

/*
 * 	Owl Carousel - Auto Height Plugin
 */

.owl-height {
	-webkit-transition: height 500ms ease-in-out;
	-moz-transition: height 500ms ease-in-out;
	-ms-transition: height 500ms ease-in-out;
	-o-transition: height 500ms ease-in-out;
	transition: height 500ms ease-in-out;
}

/*
 *  Core Owl Carousel CSS File
 */

.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;

	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;

	.owl-stage {
		position: relative;
		-ms-touch-action: pan-Y;

		&::after {
			content: ".";
			display: block;
			clear: both;
			visibility: hidden;
			line-height: 0;
			height: 0;
		}
	}

	.owl-stage-outer {
		position: relative;
		overflow: hidden;

		/* fix for flashing background */
		-webkit-transform: translate3d(0px, 0px, 0px);
	}

	.owl-controls {
		.owl-nav {
			.owl-prev, .owl-next {
				cursor: pointer;
				cursor: hand;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
			}
		}

		.owl-dot {
			cursor: pointer;
			cursor: hand;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}
}

.owl-nav {
	div {
		position: absolute;
		bottom: 161.5px;
		width: 45px;
		height: $blockHeight;
		cursor: pointer;
	}

	.owl-prev {
		left: -60px;
		background: url("//vkplaycloud.mrgcdn.ru/img/playkeynet/new/payment/review/review_arrow_left.png") center;
	}

	.owl-next {
		right: -60px;
		background: url("//vkplaycloud.mrgcdn.ru/img/playkeynet/new/payment/review/review_arrow_right.png") center;
	}
}

.disabled {
	display: none;
}

.owl-dots {
	text-align: center;
	margin-top: -33px !important;

	.active {
		opacity: 0.9;
	}
}

.owl-dot {
	width: 8px;
	height: 8px;
	margin: 5px 6px;
	opacity: 0.4;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	border-radius: 50%;
	background: $color-white;
  border: none;
  cursor: pointer;
	display: inline-block;
  padding: 0;

	.active {
		opacity: 0.9;
	}
}

.owl-carousel {
	&.owl-loaded {
		display: block;
	}

	&.owl-loading {
		opacity: 0;
		display: block;
		min-height: 150px;
		background: url("//vkplaycloud.mrgcdn.ru/img/playkeynet/new/banner/AjaxLoader.gif") no-repeat center center;
	}

	&.owl-hidden {
		opacity: 0;
	}

	.owl-refresh .owl-item {
		display: none;
	}
}

.owl-item {
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0) scale(1, 1);
}

.owl-carousel {
	.owl-item {
		position: relative;
		min-height: 1px;
		float: left;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;

		img {
			display: block;
			-webkit-transform-style: preserve-3d;
		}
	}

	&.owl-text-select-on .owl-item {
		-webkit-user-select: auto;
		-moz-user-select: auto;
		-ms-user-select: auto;
		user-select: auto;
	}

	.owl-grab {
		cursor: move;
		cursor: -webkit-grab;
		cursor: -o-grab;
		cursor: -ms-grab;
		cursor: grab;
	}

	&.owl-rtl {
		direction: rtl;

		.owl-item {
			float: right;
		}
	}
}

/* No Js */

.no-js .owl-carousel {
	display: block;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */

.owl-carousel {
	.owl-item {
		.owl-lazy {
			opacity: 0;
			-webkit-transition: opacity 400ms ease;
			-moz-transition: opacity 400ms ease;
			-ms-transition: opacity 400ms ease;
			-o-transition: opacity 400ms ease;
			transition: opacity 400ms ease;
		}

		img {
			transform-style: preserve-3d;
		}
	}

	.owl-video-wrapper {
		position: relative;
		height: 100%;
		background: $color-black;
	}

	.owl-video-play-icon {
		position: absolute;
		height: 80px;
		width: 80px;
		left: 50%;
		top: 50%;
		margin-left: -40px;
		margin-top: -40px;
		background: $color-black;
		cursor: pointer;
		z-index: 1;
		-webkit-backface-visibility: hidden;
		-webkit-transition: scale 100ms ease;
		-moz-transition: scale 100ms ease;
		-ms-transition: scale 100ms ease;
		-o-transition: scale 100ms ease;
		transition: scale 100ms ease;

		&:hover {
			-webkit-transition: scale(1.3, 1.3);
			-moz-transition: scale(1.3, 1.3);
			-ms-transition: scale(1.3, 1.3);
			-o-transition: scale(1.3, 1.3);
			transition: scale(1.3, 1.3);
		}
	}

	.owl-video-playing {
		.owl-video-tn, .owl-video-play-icon {
			display: none;
		}
	}

	.owl-video-tn {
		opacity: 0;
		height: 100%;
		background-position: center center;
		background-repeat: no-repeat;
		-webkit-background-size: contain;
		-moz-background-size: contain;
		-o-background-size: contain;
		background-size: contain;
		-webkit-transition: opacity 400ms ease;
		-moz-transition: opacity 400ms ease;
		-ms-transition: opacity 400ms ease;
		-o-transition: opacity 400ms ease;
		transition: opacity 400ms ease;
	}

	.owl-video-frame {
		position: relative;
		z-index: 1;
	}
}

/*
 * 	Owl Carousel - Video Plugin
 */

.carousel-container {
	width: 100%;
	height: 396px;
	padding-top: 1px;
	margin-top: -1px;
	position: relative;

	&::after {
		content: '';
		width: 100%;
		height: 39px;
		background-color: rgba(0, 0, 0, 0.3);
		position: absolute;
		bottom: 0;
	}
}

.carousel-container--discount {
	background: url("//vkplaycloud.mrgcdn.ru/img/playkeynet/new/banner/main_banner_discount_18_08.jpg") center;
}

.carousel-container--night-unlimit {
	background: url("//vkplaycloud.mrgcdn.ru/img/playkeynet/new/banner/carousel/night_unlimit_carousel.jpg") center;
}

.carousel-container--batman {
	background: url("//vkplaycloud.mrgcdn.ru/img/playkeynet/new/batman_banner2.jpg") center;
}

.carousel-container--GTA5 {
	background: url("//vkplaycloud.mrgcdn.ru/img/playkeynet/new/banner/main_banner_gta5.jpg") center;
	position: relative;
}

.carousel-container--gama {
	background: url("//vkplaycloud.mrgcdn.ru/img/playkeynet/new/gama_gama_banner3.jpg") center;
}

.carousel-container--new-model {
	background: url("//vkplaycloud.mrgcdn.ru/img/playkeynet/new/banner/banner_reveal-min.jpg") center;
}

.carousel-container {
	.carousel-content {
		width: $main-width;
		height: 335px;
		margin-left: auto;
		margin-right: auto;
		position: relative;

		.carousel__h1, .carousel__description {
			color: $color-white;
		}
	}

	.carousel-content--right {
		text-align: right;
	}

	.carousel-content--black {
		.carousel__h1, .carousel__description {
			color: $color-black;
		}
	}

	.carousel-content--white-black {
		.carousel__h1 {
			color: $color-white;
		}

		.carousel__description {
			color: $color-black;
		}
	}

	.carousel-content--shadow-white-true {
		.carousel__description, .carousel__h1 {
			text-shadow: 0 0 0 $color-black, 0 0 20px $color-black;
		}
	}

	.carousel-content--shadow-black-true {
		.carousel__description, .carousel__h1 {
			text-shadow: 0 0 0 $color-black, 0 0 20px $color-white;
		}
	}

	.carousel-content {
		.carousel__h1 {
			margin: 0;
			font-size: 42px;
			letter-spacing: 0px;
			margin-top: 61px;
			font-weight: bold;
			white-space: pre;
		}

		.carousel__h1--night {
			font-size: 34px;
			line-height: 46px;
			margin-top: 70px;
		}

		.carousel__description {
			font-size: 24px;
			margin: 0;
			margin-top: 22px;
			letter-spacing: 0;
			font-weight: 400;
		}

		a {
			text-decoration: none;
			color: $color-white;
		}

		.carousel-button-block {
			position: absolute;
			bottom: 60px;
		}

		.carousel-more-padding {
			padding-top: 136px;
		}

		.carousel-subscribe-button, .carousel-pay-button, .carousel-try-button-b {
			background-color: $color-blue;
			text-align: center;
			border-radius: 3px;
			cursor: pointer;
			transition: 0.3s;
			min-width: 328px;
			font-size: 24px;
			border: 0;
			line-height: 60px;
			color: $color-white;
			margin-top: 44px;
			display: inline-block;
			text-decoration: none;
			font-weight: 300;
		}

		.carousel-subscribe-button:hover {
			background-color: $color-blue-hover;
		}

		.carousel-pay-button {
			background-color: $color-play;
			float: right;
			margin-top: 0;
			border-radius: 4px;

			&:hover {
				background-color: $color-play-hover;
			}
		}

		.carousel-pay-button--left {
			float: left;
		}

		.carousel-pay-button--red {
			background-color: #ff003e !important;
			border-top: 1px solid #ff003e !important;
			border-bottom: 1px solid #ff003e !important;

			&:hover {
				background-color: #f72256 !important;
			}
		}

		.carousel-pay-button--margin {
			margin-top: 40px;
		}

		.carousel-pay-button--small {
			min-width: 249px;
		}

		.carousel-pay-button--color {
			border-top: 1px solid #f78b00;
			box-shadow: 0 4px 0 rgba(247, 138, 0, 0.6);

			&:hover {
				box-shadow: 0 1px 0 rgba(247, 138, 0, 0.6);
			}
		}

		.carousel-pay-button--green {
			background-color: $color-buy;
			border-top: 1px solid $color-buy;

			&:hover {
				background-color: $color-buy-hover;
			}
		}

		.carousel-pay-button--blue {
			background-color: $color-blue;
			border-top: 1px solid $color-blue;

			&:hover {
				background-color: $color-blue-hover;
			}
		}

		.carousel-pay-button--preorder {
			background-color: $color-white;
			color: $color-blue;
			cursor: default;
			width: 328px;
			text-transform: uppercase;
			border-top: 0;
			border-bottom: 1px solid $color-white;

			&:hover {
				background-color: $color-white;
			}
		}

		.carousel-pay-button--subscribe {
			background-color: $color-blue;
			color: $color-white;
			width: 200px;
			border-top: 0;
			border-bottom: 1px solid $color-blue;

			&:hover {
				background-color: $color-blue-hover;
			}
		}

		.carousel-try-button-b {
			background-color: rgba(0, 0, 0, 0);
			border: $border-width-default $border-style-default $color-blue;
			color: $color-white;
			margin: 0;
			padding: 17.5px 0;
			font-size: 20px;

			&:hover {
				background-color: $color-blue;
				color: $color-white;
			}
		}

		.carousel-new-button {
			overflow: hidden;
			width: 135px;
			margin-top: 0;
			float: right;
			margin-right: 34px;
			position: relative;
		}

		.carousel-new-button--margin-left {
			margin-left: 25px;
		}

		.carousel-new-button--margin-right-less {
			margin-right: 10px;
		}

		.carousel-new-button--float-left {
			float: left;
			margin-left: 25px;
		}

		.carousel-new-button--margin {
			margin-left: 0;
		}

		.carousel-new-button--margin2 {
			margin-top: 50px;
		}

		.carousel-new-button--margin65 {
			margin-top: 40px;
		}

		.carousel-new-button--preorder {
			width: 200px;
		}

		.carousel-new-button--free {
			width: 250px;

			.carousel-new-button-left, .carousel-new-button-right {
				background-color: $color-buy;
			}

			.carousel-new-button-text {
				top: 0;
				left: 16px;
				width: 200px;
				text-align: left;
				padding: 7px 0;
				white-space: pre;
				color: $color-black;
				letter-spacing: 0.9px;
				font-style: normal;
				font-weight: normal;
				line-height: 23px;
				font-size: 18px;
			}
		}

		.carousel-new-button-left, .carousel-new-button-right {
			background: #e80429;
			height: 60px;
			width: 80%;
			border-radius: 3px;
		}

		.carousel-new-button-left--preorder, .carousel-new-button-right--preorder {
			background: #147dfb;
			width: 185px;
		}

		.carousel-new-button-left {
			float: left;
			transform: skew(25deg);
		}

		.carousel-new-button-right {
			transform: skew(-25deg);
		}

		.carousel-new-button-text {
			color: $color-white;
			font-size: 24px;
			text-align: center;
			position: absolute;
			top: 16px;
			left: 16px;
			z-index: 2;
			text-transform: uppercase;
		}

		.carousel-gama-gama {
			margin: 116px 18px;
			height: 162px;
			z-index: 0;
			filter: blur(8px);
			-webkit-filter: blur(8px);
			-moz-filter: blur(8px);
			-o-filter: blur(8px);
			-ms-filter: blur(8px);
			overflow: hidden;

			&::after {
				content: '';
				height: 162px;
				width: 960px;
				background: rgba(255, 255, 255, 0.25);
				position: absolute;
			}

			.carousel-gama-gama-bg {
				position: absolute;
				top: -117px;
				left: 0;
				z-index: -2;
			}
		}
	}
}

.carousel-gama-gama-logo {
	position: absolute;
	top: 150px;
	left: calc(50% - 440px);
	z-index: 3;
	outline: 0;
	border: 0;
}

.carousel-gama-gama-break {
	position: absolute;
	top: 164px;
	left: calc(50% - 270px);
	border-right: 1px solid $color-white;
	height: 65px;
	z-index: 3;
}

.carousel-gama-gama-title {
	position: absolute;
	top: 154px;
	left: calc(50% - 239px);
	z-index: 3;
	font-size: 34px;
	width: 675px;
	text-transform: uppercase;
	color: $color-white;
	line-height: 42px;
}

.carousel-gama-gama-romb {
	position: absolute;
	top: 183px;
	left: calc(50% - -361px);
	z-index: 2;
	outline: 0;
	border: 0;
}

.carousel-img-bg {
	width: 100%;
	height: 396px;
}

.carousel-try {
	font-size: 20px;
	font-weight: 400;
	position: absolute;
	top: 225px;
	left: 536px;
	text-align: left;
}

.carousel-try-img {
	display: inline-block;
	vertical-align: top;
}

.carousel-try-play {
	margin-bottom: 19px;
}

.carousel-try-button {
	display: inline-block;
	vertical-align: top;
	margin-left: 10px;
}

.carousel-h1--padding-less {
	padding-top: 30px !important;
}

.carousel-h1--padding-more {
	padding-top: 100px !important;
}

.carousel-button-margin--less {
	margin-top: 10px !important;
}

.carousel-blue {
	text-align: center;
	border-radius: 3px;
	cursor: pointer;
	transition: 0.3s;
	width: 328px;
	font-size: 24px;
	background-color: rgba(0, 0, 0, 0.3);
	border: $border-width-default $border-style-default $color-blue;
	color: $color-white;
	margin-left: 31px;
	padding: 15px 0;
	margin-top: 40px;
	position: absolute;
	text-decoration: none;

	&:hover {
		background-color: $color-blue;
		color: $color-white;
	}
}

.carousel__additional-bottom-text {
	position: absolute;
	bottom: 30px;
	left: 0;
	white-space: pre;
}

.carousel__discount-bottom {
	color: $color-black !important;
	margin-top: 40px !important;
	text-shadow: 0 0 0 $color-black, 0 0 0.5px rgba(0, 0, 0, 0.3);
}

.carousel__discount-bottom--white {
	color: $color-white !important;
}

.carousel__month-for {
	font-size: 18px;
	font-weight: 300;
}

.carousel__discount-price {
	font-size: 38px;
	font-weight: bold;
	line-height: 38px;
	position: relative;

	&::after {
		content: '';
		position: absolute;
		top: 26px;
		left: -3px;
		width: 74px;
		height: 4px;
		background-color: #ff0039;
		transform: rotate(-15deg);
	}
}

.carousel__discount-price--short:after {
	width: 51px;
	transform: rotate(-30deg);
}

.carousel__discount-price-nosale {
	font-size: 38px;
	font-weight: bold;
	line-height: 38px;
	position: relative;
}

.carousel__price {
	font-size: 28px;
	font-weight: bold;
	margin-left: 14px;
	display: inline-block;
	vertical-align: top;
	margin-top: 2px;
}

.carousel-button {
	text-align: center;
	border-radius: 3px;
	cursor: pointer;
	transition: 0.3s;
	width: 230px;
	font-size: 24px;
	border: 0;
	padding: 16px 0;
	color: $color-white;
	display: inline-block;
	float: right;
	background-color: $color-play;
	margin-top: 0;

	&:hover {
		background-color: $color-play-hover;
	}
}

.carousel-button--left {
	float: left;
}

.carousel-button--subscribe {
	background-color: $color-blue;
	color: $color-white;
	width: 200px;
	border-top: 0;
	border-bottom: 1px solid $color-blue;

	&:hover {
		background-color: $color-blue-hover;
	}
}

.carousel-button--red {
	background-color: #ff003e !important;
	border-top: 1px solid #ff003e !important;
	border-bottom: 1px solid #ff003e !important;

	&:hover {
		background-color: #f72256 !important;
	}
}

.carousel-main-content--white .carousel-content--shadow {
	.carousel__description, .carousel__h1 {
		text-shadow: 0 0 0 $color-black, 0 0 20px $color-black;
	}
}

.carousel-main-content--black .carousel-content--shadow {
	.carousel__description, .carousel__h1 {
		text-shadow: 0 0 0 $color-black, 0 0 20px $color-white;
	}
}

.carousel-container__click-zone {
	width: $main-width;
	height: 350px;
	position: absolute;
	top: -54px;
	left: 0;
	cursor: pointer;
}

.review__container {
	width: 705px;
	margin: auto;
}

.review__block {
	/* Internet Explorer 10 */
	display: -ms-flexbox;
	-ms-flex-pack: center;
	-ms-flex-align: center;

	/* Firefox */
	display: -moz-box;
	-moz-box-pack: center;
	-moz-box-align: center;

	/* Safari, Opera, and Chrome */
	display: -webkit-box;
	-webkit-box-pack: center;
	-webkit-box-align: center;

	/* W3C */
	display: flex;
	box-pack: center;
	box-align: center;
	height: 368px;
	text-align: center;
}

.review__img-block {
	width: auto;

	img {
		width: 100%;
	}
}

@-moz-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-o-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
