@import "includes";
@import "reset";
@import "carousel";
@import "typo";
@import "loader";
@import "video";
@import "cbox-gallery";
@import "modal";
@import "circle";
@import "captcha";


// Фикс позиционирования iframe FreshChat до момента прогрузки внешних стилей
// (для предотвращения дёрганья интерфейса в момент инициализации)
#fc_frame {
  position: fixed;
}

body {
  background-color: $n-color-mono-5;
}
