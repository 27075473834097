@import "../variables";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// variables
//////////////////////////////////////////////////////://///////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// animations
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
@keyframes loader-animation {
  0% {
    width: 0%;
  }
  100% {
    left: 0%;
    width: 100%
  }
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// template-selectors
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
%line-loader {
  @include wh(100%, $n-half-gutter);
  background: $n-color-mono-1;
  animation-name: loader-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
