#colorbox {
  z-index: 16;
  outline: 0;
}

#cboxOverlay {
  z-index: 15;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#cboxLoadedContent {
  text-align: center;
  overflow: hidden !important;
}

#cboxClose {
  display: block;
  height: 24px;
  position: fixed;
  top: 10px;
  right: 10px;
  width: 24px;
  background-color: transparent;
  border: 0;
  background: url("//vkplaycloud.mrgcdn.ru/img/playkeynet/new/closeCard.png");
  outline: 0;
  cursor: pointer;
}

#cboxPrevious {
  display: block;
  height: 80px;
  width: 40px;
  position: absolute;
  top: 49%;
  left: 0;
  background-color: transparent;
  border: 0;
  background: url("//vkplaycloud.mrgcdn.ru/img/playkeynet/scr_arrows_01.png") -224px -57px;
  outline: 0;
  cursor: pointer;
}

#cboxNext {
  display: block;
  height: 80px;
  position: absolute;
  top: 49%;
  width: 40px;
  right: 0;
  background-color: transparent;
  border: 0;
  background: url("//vkplaycloud.mrgcdn.ru/img/playkeynet/scr_arrows_01.png") -224px -171px;
  outline: 0;
  cursor: pointer;
}

