/**
 * Main
 */
$main-width 					  : 996px;
$main-header-height     : 48px;
$main-footer-height     : 46px;
$main-banner-height     : 296px;
$main-font-family       : Fira Sans;
$montserrat-font-family : Montserrat;
$gutter                 : 15px;
$half-gutter            : calc($gutter / 2);
$big-gutter             : $gutter * 2;
$semi-big-gutter        : $big-gutter - $half-gutter;

/**
 * Sprites
 */
$sprite_base 				    : '//vkplaycloud.mrgcdn.ru/img/playkeynet/new/sprites/';
$sprite_advantages 			: url(sprite_base + 'sprite_advantages.png');

/**
 * Colors
 */
$color-black				: #000000;
$color-white				: #ffffff;
$color-blue					: #167ffc;
$color-blue-hover			: #4397fc;
$color-light-blue			: #0a6ede;
$color-dark-blue			: #2080f7;
$color-dark-blue-hover		: #3e93fc;
$color-light-blue-hover		: #4397fc;
$color-medium-blue			: #0066ff;
$color-play					: #F78A00;
$color-play-hover			: #f9a233;
$color-gray					: #cccccc;
$color-gray-100				: #d0d0d0;
$color-light-gray			: #e5e5e5;
$color-lighter-gray			: #f2f2f2;
$color-medium-light-gray	: #e6e6e6;
$color-shadow-gray      : #D1D1D1;
$color-medium-gray			: #8c8c8c;
$color-dark-gray			: #666666;
$color-black-gray			: #232323;
$color-light-black-gray		: #4a4a4a;
$color-light-green			: #00c66f;
$color-buy					: #25E194;
$color-buy-hover			: #00e089;
$color-yellow				: #FECF3E;
$color-yellow-hover			: #FFDE79;
$color-wet-asphalt			: #9e9e9e;
$color-light-yellow			: #ffd800;
$color-disabled				: #b2b2b2;
$color-input-disabled : #F1F1F1;
$color-dark-violet			: #16338c;
$main-blue500				: #0064FF;
$ocean-blue500				: #202E42;
$cobalt-blue1				: #0844AD;
$cobalt-blue200				: #2252A0;
$cobalt-blue250				: #022B69;
$cobalt-blue300				: #162A56;
$cobalt-blue500				: #111936;
$color-sapphire100		: #12204F;
$color-orange         : #f78b00;
$color-links-blue     : #147dfb;
$color-hard-blue	  : #0029FF;

$color-twitch: #5A3E85;
$color-vk: #4C75A3;

/**
 * Intro
 */
$intro-oferta-blue-text			: #83a5fd;
$intro-banner-text-blue			: #1862f5;
$intro-play-color-gradient-1	: #ffbb08;
$intro-play-color-gradient-2 	: #f5a019;
$intro-dark-blue				: #006ec8;
$intro-light-blue				: #2494f4;
$intro-border-orange			: #ffbc08;

// Account vars start

$border-color-gray 		  : #CCCCCC;
$border-width-default 	: 1px;
$border-style-default 	: solid;
$border-radius-default	: 3px;
$border-radius-popup    : 16px;
$border-radius-checkbox : 4px;
$border-radius-block    : 12px;

// Tariff list consts
$blockHeight			: 45px;

// Game Card
$card-default-margin-top : 15px;

// Constructor
$constructor-block-active-color : #e5f1ff;
$constructor-block-active-color-hover : #eef4fb;

//Deposit
$deposit-profit-block-orange-color: #FF6607;
$deposit-profit-block-pink-color: #FF007A;


@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes fade-in-opacity {
  0% {
    opacity: 0;
    visibility: hidden;
  }

  100% {
    opacity: 1;
    visibility: visible;
  }
}


@keyframes fade-out-opacity {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

// device widths
$medium: 1280px;
$laptop: 1366px;
$desktop-trans: 1700px;
$note: 1600px;
$desktop: 1920px;
$qhd: 2500px;

//payment
$tariffWidth: 360px;
$tariffHeight: 456px;


//font-sizes
$button-big-font-size: 24px;
$button-medium-font-size: 18px;
$button-default-font-size: 17px;

//carets
$caret-down-default-width: 6px;

//backgrounds
$overlay-background: rgba(0, 0, 0, 0.85);


/**
* redesign 2020 variables (TODO - убрать основные после полного редизайнаа)
 */
$n-gutter-1: 8px;
$n-half-gutter: calc($n-gutter-1 / 2);
$n-gutter-2: 16px;
$n-gutter-3: 24px;
$n-gutter-4: 32px;
$n-gutter-5: 40px;
$n-letter-spacing-default: 0.1rem;
$n-letter-spacing-percent-5: 0.05em;
$n-letter-spacing-percent-10: 0.1em;
$n-transition-time: .3s;

$n-col-width: 312px;

$n-color-mono-1: $color-white;
$n-color-mono-2: #B8B8B8;
$n-color-mono-3: #666666;
$n-color-mono-4: #313642;
$n-color-mono-5: #262930;
$n-color-mono-6: $color-black;

$n-color-primary: #1a3fff;
$n-color-secondary: #0064FF;

$n-color-accent-1: #FFF500;
$n-color-accent-2: #FECF3E;
$n-color-accent-3: #FF6607;
$n-color-accent-4: #FF3742;
$n-color-accent-5: #FF0377;
$n-color-accent-6: #3AFFAE;
$n-color-accent-7: #4CFF3D;
$n-color-accent-8: #FFF8E8;

$n-color-footer: #808080;
$n-color-button-blue: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.17) 50.76%, rgba(255, 255, 255, 0) 51.16%), linear-gradient(180deg, #4D93FF 0%, #0768FF 100%);
$n-color-button-yellow: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50.66%, rgba(255, 255, 255, 0) 51.12%), linear-gradient(180deg, #FFE89F 0%, #FECF3E 100%);
$n-color-button-orange: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50.66%, rgba(255, 255, 255, 0) 51.12%), linear-gradient(180deg, #FF9B5C 0%, #FF6607 100%);
$n-color-button-black: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50.66%, rgba(255, 255, 255, 0) 51.12%), linear-gradient(180deg, #262626 0%, #000000 100%);
$n-color-button-green: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.2) 50.66%, rgba(255, 255, 255, 0) 51.12%), #00D5A2;

$n-font-size-eq-8px: 0.55rem;
$n-font-size-eq-10px: 0.65rem;
$n-font-size-eq-12px: 0.75rem;
$n-font-size-eq-13px: 0.82rem;
$n-font-size-eq-14px: 0.87rem;
$n-font-size-eq-16px: 1rem;
$n-font-size-eq-17px: 1.06rem;
$n-font-size-eq-18px: 1.13rem;
$n-font-size-eq-20px: 1.3rem;
$n-font-size-eq-22px: 1.4rem;
$n-font-size-eq-24px: 1.5rem;
$n-font-size-eq-26px: 1.6rem;
$n-font-size-eq-30px: 1.9rem;
$n-font-size-eq-36px: 2.25rem;
$n-font-size-eq-48px: 3rem;

$n-width-hd: 1920px;
$n-width-notebook: 1366px;
$n-width-laptop: 1024px;
$n-width-pad: 978px;
$n-width-tablet: 768px;
$n-width-phone: 540px;

$rolled-header-panel-height: 55px;
$rolled-header-panel-transition-time: 1s;


@keyframes linear-animate-y {
  100% { background-position-y: 100%; }
}

@keyframes linear-animate-x {
  100% { background-position-x: 100%; }
}
